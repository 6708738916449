import React, { useState } from 'react';
import { navigate } from '@reach/router';

import Search from 'src/svg/search.inline.svg';
import Clear from 'src/svg/clear.inline.svg';

import './style.scss';

const BannerAlgoliaSearchBar = (props) => {
  const { onClearHandler = null, className = '' } = props;
  const [query, setQuery] = useState('');

  const onInputChange = (e) => {
    e.persist();
    setQuery(e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?query=${query}`, { replace: false });
  }

  return (
    <div className={`BannerAlgoliaSearchBar ${className}`}>
      <form className="SearchForm" onSubmit={onSubmit}>
        <input id="search-input-banner"
               type="text"
               style={{borderRadius: "6px 0 0 6px"}}
               placeholder="Search"
               aria-label="Search"
               value={query}
               onChange={onInputChange}
               required
        />
        <button type="submit" style={{borderRadius: "0 6px 6px 0", width: 50}}>
          <Search id="search-btn" />
        </button>
        {query && query !== '' && (
          <div
            className="Navigation__clearButton"
            role="button"
            tabIndex={0}
            onClick={() => {
              setQuery('');
              if (onClearHandler) {
                onClearHandler();
              }
            }}
            onKeyDown={() => {
              setQuery('');
              if (onClearHandler) {
                onClearHandler();
              }
            }}
          >
            <Clear />
          </div>
        )}
      </form>
    </div>
  )
}

export default BannerAlgoliaSearchBar;
